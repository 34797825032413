import Vue from "vue";
import SpinnerMixin from "@/mixins/spinner";
import { addDays } from "@/helpers/dateTimeHelper";
import { mapGetters } from "vuex";
export default Vue.extend({
    mixins: [SpinnerMixin],
    data() {
        return {
            offices: [],
            officeId: null,
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1, 3),
            endDate: new Date(),
            validationOptions: {
                rules: {
                    startDate: {
                        required: true
                    },
                    endDate: {
                        required: true
                    }
                }
            }
        };
    },
    computed: {
        ...mapGetters({
            hasMultipleOffices: "account/hasMultipleOffices",
            getSingleOfficeId: "account/getSingleOfficeId"
        })
    },
    methods: {
        search() {
            // TODO: Start and end date were not in the button search. Test needed
            const startDate = new Date(this.startDate.setHours(0, 0, 0, 0));
            const endDate = new Date(addDays(this.endDate, 1)
                .setHours(0, 0, 0, 0));
            this.$emit("search", {
                officeId: this.officeId,
                startDate: startDate,
                endDate: endDate
            });
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.offices = await this.$store.dispatch("office/getAll", {
                load: true
            });
            this.offices.unshift({
                id: null,
                name: this.$t('offices.all')
            });
            if (!this.hasMultipleOffices)
                this.officeId = this.getSingleOfficeId;
            this.hideSpinner();
        }
        catch (errors) {
            this.hideSpinner();
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
        }
    }
});
