import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import SearchToolbar from "@/components/Templates/Statistics/Company/SearchToolbar.vue";
import ServiceStatistics from "@/components/Charts/ServiceStatistics.vue";
import ServiceRevenueStatistics from "@/components/Charts/ServiceRevenueStatistics.vue";
import ServiceEmployeeCostStatistics from "@/components/Charts/ServiceEmployeeCostStatistics.vue";
import ServiceProfitStatistics from "@/components/Charts/ServiceProfitStatistics.vue";
import HealthPathsDurationStatistics from "@/components/Charts/HealthPathsDurationStatistics.vue";
import HealthPathsDurationStandardDeviationStatistics from "@/components/Charts/HealthPathsDurationStandardDeviationStatistics.vue";
import HealthPathsGymConversionStatistics from "@/components/Charts/HealthPathsGymConversionStatistics.vue";
import CustomersRetentionStatistics from "@/components/Charts/CustomersRetentionStatistics.vue";
import { ButtonPlugin } from "@syncfusion/ej2-vue-buttons";
import { TabPlugin } from '@syncfusion/ej2-vue-navigations';
Vue.use(ButtonPlugin);
Vue.use(TabPlugin);
export default Vue.extend({
    components: {
        "search-toolbar": SearchToolbar,
        "service-statistics": ServiceStatistics,
        "service-revenue-statistics": ServiceRevenueStatistics,
        "service-employee-cost-statistics": ServiceEmployeeCostStatistics,
        "service-profit-statistics": ServiceProfitStatistics,
        "health-paths-duration-statistics": HealthPathsDurationStatistics,
        "health-paths-duration-standard-deviation-statistics": HealthPathsDurationStandardDeviationStatistics,
        "health-paths-gym-conversion-statistics": HealthPathsGymConversionStatistics,
        "customers-retention-statistics": CustomersRetentionStatistics
    },
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("statistics.company"),
            chartRefs: [
                "serviceStatistics",
                "serviceRevenueStatistics",
                "serviceEmployeeCostStatistics",
                "serviceProfitStatistics",
                "healthPathsDurationStatistics",
                "healthPathsDurationStandardDeviationStatistics",
                "healthPathsGymConversionStatistics",
                "customersRetentionStatistics"
            ],
            updateChartsData: null,
            granularity: 3
        };
    },
    methods: {
        async updateCharts(params) {
            params = params || this.updateChartsData;
            this.updateChartsData = params;
            this.showSpinner();
            try {
                for (const p in this.chartRefs) {
                    await this.$refs[this.chartRefs[p]].loadData(params.startDate, params.endDate, params.employeeId, params.officeId);
                }
                this.hideSpinner();
            }
            catch (errors) {
                this.hideSpinner();
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                    error: errors[0].message
                }));
            }
        }
    }
});
